.listItem {
  overflow: hidden;
  backface-visibility: visible;
  min-height: 50px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: calc(100% + 60px);
  margin: 0 -30px;
  display: flex;
  align-items: center;
}
.listItemImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 90px;
}
.contentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  & + * {
    margin-top: 5px;
  }
}
.title {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3rem;
  font-family: "Hind";
  overflow: hidden;
  max-width: calc(100% - 40px);
}
.subtitle {
  color: #767676;
  margin-right: 10px;
}
:global(.btn).btn {
  background-color: #fff;
  border-color: #fff;
  width: 100%;
}
