.content {
  max-width: 800px;
}
.jumbotronContent {
  max-width: 100%;
}
.content h4 {
  font-weight: 400;
  color: #a7a7a7;
  margin-bottom: 12px;
  padding: 8px 0;
  font-size: 1.3rem;
}
.imageBox,
.videoBox,
.content p {
  padding: 8px 0;
  margin-bottom: 22px;
}
.content p {
  font-size: 16px;
  color: #666;
  font-weight: 300;
}
.imageBox,
.videoBox {
  width: 100%;
  max-width: 800px;
}
.videoBox video,
.imageBox img {
  display: block;
  max-width: 100%;
}
.navigation {
  padding: 0 1rem;
}
.navigation button {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #888;
  font-weight: 400;
  background: transparent;
  border: 0;
  text-align: left;
  padding: 8px 0;
}
.navigation button:hover,
.navigation button:focus {
  color: #444;
  outline: none;
}
.navigation button:hover {
  color: #444;
}
.navigation .activeBtn {
  color: #333;
}
.navigation .activeBtn .arrowImg {
  display: block;
}
.arrowImg {
  display: none;
  width: 20px;
  margin-right: 10px;
}
.arrowImg img {
  display: block;
  width: 100%;
}
/* comments */
.commentsForm {
  padding: 30px 20px;
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.commentsFormHeader {
  color: #a7a7a7;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.commentDetails {
  display: flex;
  align-items: center;
}
.authorAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  background: #a7a7a7;
}
.authorAvatar img {
  display: block;
  width: 100%;
}
.authorName {
  font-size: 0.9rem;
}
.commentText {
  font-size: 0.9rem;
  padding-left: 57px;
}
.date {
  color: #aaa;
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 5px;
}
@import "overwrites";

.jumbotron {
  align-items: center;
  background-image: url("~assets/images/e21.jpg");
  background-size: cover;
  display: flex;
  height: 70vh;
  min-height: 600px;
  position: relative;
  color: #fff;
  &::before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.55), transparent);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.jumbotron p {
  color: #fff;
}
.content {
  position: relative;
  z-index: 1;
}
.linkGreen {
  font-weight: 500;
  color: #00cf80;
  &:hover {
    color: #02975e;
    text-decoration: none;
  }
}
.linkGreen img {
  padding-top: 4px;
  width: 12px;
}
.projectTitle {
  font-weight: 300;
  margin-top: 10px;
}
.price {
  font-size: 1.8rem;
  font-weight: 300;
}
.pinsVersion {
  color: #00cf80;
}
.pins21,
.pins4 {
  padding-left: 25px;
  font-size: 0.9rem;
}
.pins4 {
  background: url(~assets/images/icon_4pin_19px_white.svg) left center no-repeat;
}
.pins21 {
  background: url(~assets/images/icon_21pin_19px_white.svg) left center
    no-repeat;
}
.pinsVersion.pins4 {
  background: url(~assets/images/icon_4pin_19px_green.svg) left center no-repeat;
}
.pinsVersion.pins21 {
  background: url(~assets/images/icon_21pin_19px_green.svg) left center
    no-repeat;
}
/*  */
.footer {
  border-top: 1px solid #ccc;
}
.footerCodeSpan,
.footerTaxSpan {
  font-size: 0.875rem;
  color: #3838388a;
}
.footerCodeSpan {
  font-size: 0.95rem;
  font-weight: 500;
}
.footerTotalPrice {
  font-size: 1.875rem;
  text-align: right;
}
.openInAppBtn {
  color: #fff;
  background-color: #5048e2;
  border-color: #5048e2;
  border-radius: 0;
  padding: 11px 0.75rem 9px;
  font-weight: 500;
  font-size: 1rem;
}
.openInAppBtn:hover {
  color: #fff;
  background-color: #3027dd;
  border-color: #2b22d5;
  text-decoration: none;
}
.openInAppBtn:focus {
  color: #fff;
  background-color: #3027dd;
  border-color: #2b22d5;
  box-shadow: 0 0 0 0.2rem rgba(106, 99, 230, 0.5);
}
.addToCartBtn:hover img {
  filter: contrast(0.5) brightness(10);
}
.items {
  padding-left: 0;
}
.Line-item {
  /* margin-bottom: 20px; */
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// TODO: change BEM names which are the legacy of bootstrapped app
.Line-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  min-width: 140px;
}

.Line-item__content {
  width: 100%;
  min-width: -webkit-fill-available;
}
.additionalInfo,
.Line-item__quantity,
.Line-item__variant-title {
  color: #767676;
  margin-right: 10px;
  font-size: 0.875rem;
}
.Line-item__variant-title {
  text-align: right;
  margin-bottom: 10px;
}
.Line-item__quantity strong {
  color: #444;
}
.Line-item__quantity {
  min-width: 90px;
}
.Line-item__title {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3rem;
  font-family: "Hind";
  overflow: hidden;
  max-width: calc(100% - 40px);
}

.Line-item__price {
  font-size: 1.25rem;
  padding-right: 5px;
  text-align: right;
  color: #767676;
}

.Line-item__content-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__quantity-update-form {
  display: inline;
}

@media (max-width: 450px) {
  .Line-item__img {
    min-width: 70px;
  }
}

.tagLabel {
  font-family: "Roboto";
  font-size: 0.75rem;
  border-radius: 20px;
  padding: 1px 10px 0;
  display: inline-flex;
  font-weight: 600;
  text-transform: uppercase;
  left: 15px;
  top: 15px;
  color: #fff;
}
.tagLabelBgBlue {
  background: #5048e2;
}
.tagLabelBgOrange {
  background: orange;
}
.tagLabelBgGreen {
  background: #00cf80;
}
@media (min-width: 992px) {
  .tagLabelsBox {
    justify-content: flex-end;
  }
}
