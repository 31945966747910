.dialogContent:global([data-reach-dialog-content]) {
  height: 100%;
  max-width: none;
  width: auto;
  margin: 0;
  padding: 0;
  background: transparent;
}
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  width: 100vw;
  max-width: 500px;
  box-shadow: 0px 0px 20px 30px rgba(0, 0, 0, 0.075), 0px 2px 10px 2px rgba(0, 0, 0, 0.22);
}
.content {
  transition: width 500ms, height 500ms;
  position: relative;
  background-color: #f2f2f2;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.075), 0 4px 14px -2px rgba(0, 0, 0, 0.12);
  max-height: 90vh;
  overflow: hidden;
  padding: 30px 40px;
  border-radius: 4px 4px 0 0;
}
.header {
  margin-bottom: 15px;
}
.headline {
  font-family: "Ginora Sans";
  font-weight: 300;
  color: #222;
  font-size: 36px;
}
.headline h1 {
  font-weight: 300;
  font-size: 3rem;
}
.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}
.closeModalBtn:focus,
.closeModalBtn:active {
  box-shadow: 0 0 0 5px #5048e240;
  outline: none;
}

.subtitle {
  color: #aaa;
}
