:global(.btn).backLink {
  display: flex;
  font-size: 1rem;
  color: #00cf80;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 5px;
  background-color: transparent;
  border-color: transparent;
  &:hover {
    color: #00cf80;
  }
}
