.carousel {
  width: 100%;
  :global(.carousel-control-next) {
    right: -40px;
    width: 40px;
  }
  :global(.carousel-control-prev) {
    left: -40px;
    width: 40px;
  }
  :global(.carousel-inner) {
    margin-left: 7px;
    width: calc(100% - 14px);
  }
}
.topSpan {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.topSpanLeft {
  margin-left: 7px;
  color: #fff;
  opacity: 0.7;
  margin-bottom: 10px;
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: Ginora sans;
}

@media (max-width: 575px) {
  .carousel {
    :global(.carousel-control-next) {
      right: 0;
    }
    :global(.carousel-control-prev) {
      left: 0;
    }
  }
}
