.text {
  font-size: 1.5rem;
  font-weight: 400;
  color: #555;
  margin: 0 auto;
  position: relative;
  width: 700px;
  z-index: 4;
  left: 40px;
}
.idea {
  margin: 0 5px;
}
.toCreate {
  // left: 67px;
  left: 127px;
  position: absolute;
  opacity: 0;
  display: none;
}
.devkit {
  color: #00cf80;
  font-weight: 600;
  // left: 75px;
  position: absolute;
  width: 100%;
  text-align: center;
}
.devkits {
  position: absolute;
  width: 100%;
  font-size: 1.5rem;
  z-index: 4;
}
