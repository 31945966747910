.container {
  position: fixed;
  right: 90px;
  top: 90px;
  padding-bottom: 20px;
  z-index: 99999;
}
.snackbar {
  border: 0;
  background-color: #fff;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 0;
  transform: translateY(200%);
  box-shadow: 2px 2px 14px #444;
}
.snackbar.snackbarOpened {
  height: initial;
  min-height: 70px;
  transform: none;
}
.snackbar {
  overflow: hidden;
  min-width: fit-content;
  width: fit-content;
  max-width: 620px;
  cursor: pointer;
  opacity: 0;
  border-radius: 2px 2px 40px 2px;
  min-width: 328px;
  max-width: 328px;
}
.snackbar a {
  color: #009cdf;
}
.snackbar a:focus,
.snackbar a:hover {
  color: #58c9f9;
}
.snackbar.snackbarOpened {
  height: initial;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
}
.eventIcon {
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: center;
}
.eventIcon img {
  display: block;
}
.snackbarContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.snackbarContent span {
  font-size: 1rem;
  font-weight: 300;
  color: #383838;
}
.failure .snackbarContent span {
  color: #fff;
}
.failure {
  background: #f54337;
}
.closeBtn {
  height: 25px;
  margin-top: -10px;
  opacity: 0.6;
  :hover {
    transform: scale(1.2);
  }
}
@media (max-width: 767px) {
  .container {
    left: 0px !important;
    right: 0px;
    width: 100%;
  }
  .container .snackbar {
    min-width: 100%;
  }
  .container [class="snackbar snackbarOpened"] ~ .snackbar.toast {
    margin-top: 20px;
  }
  .container [class="snackbar snackbarOpened"] {
    margin-bottom: 0;
  }
}
