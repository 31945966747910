.heading {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 0;
  font-family: "Hind", "Roboto", sans-serif;
}
.subHeading {
  text-transform: uppercase;
  color: rgba(56, 56, 56, 0.54);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Hind", "Roboto", sans-serif;
}
.linkGreen {
  font-weight: 500;
  color: #00cf80;
  &:hover {
    color: #02975e;
    text-decoration: none;
  }
}
.linkGreen img {
  padding-top: 4px;
  width: 12px;
}
.summary,
.tableSection,
.tableHead {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
  margin-bottom: 15px;
}
.tableHead {
  padding-left: 20px;
  padding-right: 10px;
}
.tableSection {
  padding-left: 35px;
  padding-right: 25px;
}
.tableSectionDisabled .tableRow {
  opacity: 0.5;
  cursor: not-allowed;
}
.projectName {
  font-size: 1.25rem;
  font-weight: 400;
  color: #444;
}
.projectUntitled {
  opacity: 0.5;
}
.tableRow {
  padding: 5px 0;
}
.deleteBtn {
  position: relative;
}
.deleteBtn img {
  height: 20px;
  margin-top: -3px;
  margin-right: 6px;
}
.tableRow .deleteBtn {
  border: 0;
  background: transparent;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  height: 28px;
  width: 28px;
  min-width: 28px;
}
.tableRow .deleteBtn img {
  height: 20px;
  margin-top: -3px;
  margin-right: 0;
}
.elementName {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3rem;
  font-family: "Hind";
  color: #383838;
  overflow: hidden;
  max-width: calc(100% - 40px);
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  min-width: 140px;
}
.price {
  font-size: 1.25rem;
  padding-right: 5px;
}
.type {
  color: #777;
  margin-right: 10px;
}

.Line-item__content-row {
  display: inline-block;
  margin-bottom: 5px;
  position: relative;
}
.Line-item__quantity-container {
  display: flex;
  align-items: center;
}

.Line-item__quantity-update {
  color: #5047e2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  font-family: monospace;
  padding: 0;
  border: 2px solid #ccc;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  padding-bottom: 2px;
  &:focus,
  &:active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border: 2px solid #767676;
  padding: 0;
  border-radius: 0;
  margin: 0 -2px;
  z-index: 1;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}
.summary h5 {
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 5px 0;
}
.summary p {
  font-size: 0.875rem;
  color: #3838388a;
  margin-bottom: 0;
}
.totalPrice {
  font-size: 1.875rem;
  text-align: right;
}
@media (max-width: 450px) {
  .tableHead,
  .tableSection {
    padding-left: 10px;
    padding-right: 10px;
  }
}
