.btnText {
  font-weight: 400;
  font-family: Hind;
  letter-spacing: 0.64px;
}
.btn.inactive .svgSecondary,
.btn:hover .svgSecondary {
  filter: brightness(4.5);
}
.svgSecondary {
  transition: filter 200ms;
}
.btn.inactive:global(.btn-outline-primary)[disabled] {
  background-color: #5048e2;
  color: #fff;
}
