.content {
  max-width: 800px;
}
.content h4 {
  font-weight: 300;
  color: #555;
  margin-bottom: 12px;
  padding: 8px 0;
}
.imageBox,
.videoBox,
.content p {
  padding: 8px 0;
  margin-bottom: 22px;
}
.content p {
  font-size: 16px;
  color: #666;
  font-weight: 300;
}
.imageBox,
.videoBox {
  width: 100%;
  max-width: 800px;
}
.videoBox video,
.imageBox img {
  display: block;
  max-width: 100%;
}
.navigation {
  padding: 0 1rem;
  position: sticky;
  top: 120px;
}
.navigation a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #888;
  font-weight: 400;
  background: transparent;
  border: 0;
  text-align: left;
  padding: 8px 0;
}
.navigation a:hover,
.navigation a:focus {
  color: #444;
  outline: none;
  text-decoration: none;
}
.navigation a:hover {
  color: #444;
}
.navigation .activeBtn {
  color: #333;
}
.navigation .activeBtn .arrowImg {
  display: block;
}
.arrowImg {
  display: none;
  width: 20px;
  margin-right: 10px;
}
.arrowImg img {
  display: block;
  width: 100%;
}
.headingAnchor {
  position: absolute;
  top: -200px;
}
.video {
  width: 100%;
}
