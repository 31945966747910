.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  position: relative;
}
.disabled * {
  cursor: not-allowed;
}
.disabled::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.disabled.stripes::after {
  // background: #fff url(~assets/images/slash.png) repeat;
  opacity: 0.4;
}
