@import "overwrites";

.jumbotron {
  align-items: center;
  background-image: url("~assets/images/e21.jpg");
  background-size: cover;
  display: flex;
  height: 100vh;
  position: relative;
  &::before {
    background: #5048e2b3;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.jumbotronText {
  color: #fff;
  font-family: Ginora Sans;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
}

.greenSpan {
  color: $green;
}

.btn {
  background-color: #00000050;
}
.mainBtn:global(.btn-secondary) {
  background-color: #0003;
  border-color: #ffffff8a;
  color: #fff;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border-color: #ffffff6a;
    background-color: #0005;
  }
}
