.header {
  margin-bottom: 15px;
}
.headline {
  font-family: "Ginora Sans";
  font-weight: 300;
  color: #222;
  font-size: 36px;
}
.headline h1 {
  font-weight: 300;
  font-size: 3rem;
}
.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  box-shadow: 0px 0px 20px 30px rgba(0, 0, 0, 0.075),
    0px 2px 10px 2px rgba(0, 0, 0, 0.22);
}
.content {
  position: relative;
  background-color: #f2f2f2;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.075),
    0 4px 14px -2px rgba(0, 0, 0, 0.12);
  max-height: 90vh;
  overflow: hidden;
  padding: 30px 40px;
  width: 450px;
  border-radius: 4px 4px 0 0;
}
.inputGroup {
  position: relative;
  margin: 25px 0 10px;
}

.inputGroup input:focus,
.inputGroup input:active {
  outline: none;
  border-color: #5048e2;
  background: #f2f2f2;
}
.validationMessage {
  color: #e00d1f;
  font-weight: 300;
  padding: 0 0 10px;
  display: none;
}
.validationMessageVisible {
  display: block;
}
.inputGroup .inputInvalid {
  background: rgba(224, 13, 31, 0.08);
  border-bottom-color: rgba(224, 13, 31, 0.75);
}
/* New styles */
.disabled {
  opacity: 0.15;
}
.info {
  color: #888;
  font-size: 0.8rem;
}
.textInput {
  width: 100%;
  height: 48px;
  border: 0;
  border-bottom: 2px solid #bbb;
  background: transparent;
  color: #222;
  font-weight: 300;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.label {
  font-size: 16px;
  color: #888;
  font-family: "Ginora Sans";
}
