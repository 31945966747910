.container {
  position: relative;
  // background-color: inherit;
  background-color: #f2f2f2;
}
.control {
  padding: 5px 30px 5px 10px;
  width: 100%;
  background: inherit;
  border: 2px solid #ccc;
  transition: all 100ms;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-color: #5048e2;
  }
  &:focus + .label ~ .clearBtn {
    display: block;
  }
  &:focus + .label ~ .subText {
    display: block;
  }
}
.variantSmall {
  &:focus + .label,
  // &:not([data-value=""]) + .label,
  &:not([value=""]) + .label {
    top: -8px;
    font-size: 14px;
    background-color: #fff;
  }
  &:focus,
  // &:not([data-value=""]),
  &:not([value=""]) {
    background-color: #fff;
  }
}
// .variantSmall.formControlled {
//   &:focus + .label,
//   &:not([data-value=""]) + .label {
//     top: -8px;
//     font-size: 14px;
//     background-color: #fff;
//   }
//   &:focus,
//   &:not([data-value=""]) {
//     background-color: #fff;
//   }
// }
.variantLarge {
  &:focus + .label,
  &:not([value=""]) + .label {
    top: -8px;
    font-size: 14px;
    background-color: #f2f2f2;
  }
  &:focus,
  &:not([value=""]) {
    background-color: #f2f2f2;
  }
}
// .variantLarge.formControlled {
//   &:focus + .label,
//   &:not([data-value=""]) + .label {
//     top: -8px;
//     font-size: 14px;
//     background-color: #f2f2f2;
//   }
//   &:focus,
//   &:not([data-value=""]) {
//     background-color: #f2f2f2;
//   }
// }
.textarea {
  &:focus + .label,
  &:not(:empty) + .label {
    top: -8px;
    font-size: 14px;
    background-color: #fff;
  }
  &:focus,
  &:not(:empty) {
    background-color: #fff;
  }
}
.label {
  position: absolute;
  left: 15px;
  top: 13px;
  background: inherit;
  padding: 2px 8px;
  cursor: text;
  transition: font-size 200ms, top 200ms, background-color 200ms;
  line-height: 0.7rem;
  color: #aaa;
}
:global(.btn).clearBtn {
  display: none;
  position: absolute;
  right: 6px;
  top: 6px;
  background: transparent;
  border: transparent;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
}
:global(.btn).clearBtn:hover {
  background-color: #5048e240;
}

.error {
  color: #e00d1f;
  font-weight: 300;
}
.variantLarge ~ :global(.btn).clearBtn {
  top: 10px;
}
.variantLarge {
  background: rgba(0, 0, 0, 0.05);
  border: 2px solid transparent;
  border-bottom: 2px solid #bbb;
  height: 48px;
  & + label {
    background: initial;
    font-size: 18px;
    top: 17px;
  }
}
.subText {
  font-size: 14px;
  color: #888;
  position: absolute;
  bottom: -10px;
  right: 40px;
  font-weight: 300;
  background: #f2f2f2;
  padding: 2px 6px;
  z-index: 2;
  display: none;
}
