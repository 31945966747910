.heading {
  font-family: Ginora Sans;
  font-weight: 300;
}
.container {
  color: rgba(56, 56, 56, 0.95);
  display: flex;
  flex-direction: column;
  font-family: Hind;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  transition: opacity 300ms;
  height: 100%;
  p {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
