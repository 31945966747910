.heading {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 0;
  font-family: "Hind", "Roboto", sans-serif;
}
.subHeading {
  text-transform: uppercase;
  color: rgba(56, 56, 56, 0.54);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Hind", "Roboto", sans-serif;
}
.linkGreen {
  font-weight: 500;
  color: #00cf80;
  &:hover {
    color: #02975e;
    text-decoration: none;
  }
}
.linkGreen img {
  padding-top: 4px;
  width: 12px;
}
.text {
  color: rgba(56, 56, 56, 0.54);
}
.hintText {
  min-height: 50px;
}
.hintText span {
  padding-top: 4px;
}
.tile {
  height: 422px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  flex-direction: column;
  position: relative;
  display: flex;
  flex: 100%;
  min-width: 240px;
  margin: 0 7.5px 20px;
  border-radius: 3px;
}
.imageBox {
  max-height: 37%;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  position: relative;
}
.sharedProject .imageBox:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 40%;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.7), transparent);
}
.image {
  object-fit: cover;
  width: 100%;
  display: block;
}
.body {
  padding: 15px;
}
.tile .text,
.title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 1.625rem;
  font-family: "Hind";
  color: #383838;
  overflow: hidden;
}
.title {
  line-height: 1.85rem;
  min-height: 60px;
}
.tile .text {
  font-size: 14px;
  color: rgba(56, 56, 56, 0.54);
  height: 50px;
}
.userName,
.label {
  font-size: 14px;
  color: rgba(56, 56, 56, 0.54);
}
.type {
  font-weight: 500;
  color: rgba(56, 56, 56, 0.75);
}
:global(.btn).cartBtn {
  text-transform: uppercase;
  height: 50px;
  min-height: 50px;
  line-height: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.userAvatar {
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(56, 56, 56, 0.54);
  margin-right: 10px;
  position: relative;
  top: -5px;
}
.userAvatar img {
  display: block;
  width: 100%;
}
.typeWrapper {
  padding: 10px 0 15px;
}
.userName {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.deleteBtn {
  background: rgba(56, 56, 56, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 0 3px 0 0;
  &:hover {
    background: rgba(32, 32, 32, 0.54);
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}
.addProjectBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0;
  background: transparent;
  font-size: 1.625rem;
  font-weight: 400;
  &:hover {
    background: #f8f8f8;
  }
  &:focus,
  &:active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}
.addProjectBtn img {
  border: 2px solid #5048e2;
  padding: 12px;
  border-radius: 50%;
  margin-bottom: 15px;
  height: 70px;
}
.price {
  padding-top: 6px;
  font-size: 1.375rem;
  font-family: "Hind";
  color: #383838;
}
.views {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 16px;
  color: #444;
}
.views span {
  margin-right: 5px;
}
@media (min-width: 768px) {
  .tileXs {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}
@media (min-width: 992px) {
  .tileSm {
    flex: 0 0 25%;
    max-width: calc(25% - 15px);
  }
}
@media (min-width: 1280px) {
  .tileMd {
    flex: 0 0 20%;
    max-width: calc(20% - 15px);
  }
}
@media (min-width: 1600px) {
  .tileLg {
    flex: 0 0 16.666666%;
    max-width: calc(16.666666% - 15px);
  }
}
