.timeline {
  display: flex;
  position: absolute;
  top: 330px;
  &::after {
    background: #f2f2f2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: calc(25% / 2 - 18px);
  }
  padding-right: 100px;
  width: 1000px;
  align-items: flex-end;
}
.timelineArrow {
  border-top: 2px dashed #a5a5a5;
  left: 0;
  position: absolute;
  right: 0;
  top: 62px;
  &::after {
    border-top: 2px solid #a5a5a5;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: -4px;
    transform: rotate(148deg);
    width: 20px;
  }
  &::before {
    border-top: 2px solid #a5a5a5;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: -8px;
    transform: rotate(32deg);
    width: 20px;
  }
}
.timeline > * + * {
  margin-left: 20px;
}
.timelineDot {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 25%;
  z-index: 1;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
.dot {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #a5a5a5;
  height: 20px;
  width: 20px;
}
.active {
  color: rgba(0, 0, 0, 0.8);
  .dot {
    border: 3px solid #333;
    display: flex;
    padding: 0;
    cursor: pointer;
    &::after {
      background-color: #00cf80;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin: auto;
      border-radius: 10px;
    }
  }
}
.dotTitle {
  font-size: 1.2rem;
}
