.imagesSection {
  display: flex;
  flex-direction: column;
}
.thumbnails {
  margin: -20px 5px 0;
  width: 410px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  z-index: 1;
  @media (max-width: 1400px) {
    width: 360px;
  }
  @media (max-width: 1200px) {
    width: 310px;
  }
}

.thumbnail {
  background: #fff;
  border: none;
  margin-top: 10px;
  margin: 5px;
  width: calc(25% - 10px);
  padding: 0;
  box-shadow: 4px 4px 20px #00000033;
  img {
    width: 100%;
    object-fit: contain;
    background-color: #fff;
  }
  :focus,
  :active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}
.thumbSelected {
  border-style: solid inset solid solid;
  border-color: #5048e2;
  border-width: 3px;
}
.largeImage {
  background-color: #fff;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 4px 4px 20px #00000033;
  overflow: hidden;
  > img {
    max-height: 100%;
    object-fit: contain;
    padding: 10px;
  }
}

.galleryBtn:global(.btn-light) {
  position: absolute;
  top: calc(50% - 28px);
  background-color: transparent;
  border-color: transparent;
}
.galleryBtnLeft {
  left: 0;
  transform: rotate(180deg);
}
.galleryBtnRight {
  right: 0;
}
