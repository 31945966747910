.finalScene {
  align-items: center;
  background-color: #f2f2f2;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 2.2rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 3;
  color: #555;
  border-top: 1px solid #ccc;
}
.greenText {
  color: #00cf80;
  font-weight: 600;
}
.subtitle {
  font-size: 1.4rem;
  font-family: Roboto;
}
.annotation {
  color: #888;
  font-size: 1rem;
  position: absolute;
  bottom: 5vh;
}
