.container {
  position: relative;
  display: inline-flex;
  align-items: center;
  &:hover .checkMark {
    background: #97adff;
  }
}
.input {
  // display: none;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-height: 19px;
  height: 100%;
}
.checkMark {
  &:hover {
    cursor: pointer;
  }
  position: relative;
  margin: 0;
  height: 19px;
  width: 19px;
  border: 0;
  border-radius: 3px;
  background: rgba(155, 155, 155, 0.6);
}
.checkMarkActive {
  background: #5048e2;
  &::after {
    position: absolute;
    content: "";
    left: 6px;
    top: 2px;
    width: 8px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.label {
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 0 10px;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  text-overflow: ellipsis;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 6px 10px 6px 0;
  user-select: none;
  border-radius: 4px;
}
.active {
  top: -20px;
}
.variantOutline {
  .checkMark {
    height: 23px;
    width: 23px;
    border: 3px solid #3838388a;
    border-radius: 3px;
    background-color: transparent;
  }
  .checkMarkActive {
    &::after {
      position: absolute;
      content: "";
      left: 6px;
      top: 2px;
      width: 7px;
      height: 11px;
      border: solid #3838388a;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
