.content {
  position: relative;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 20px 30px rgba(0, 0, 0, 0.075), 0px 2px 10px 2px rgba(0, 0, 0, 0.22);
  max-height: 90vh;
  overflow: hidden;
}
.btn {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  padding: 2px;
  :focus,
  :active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}
.body {
  margin: 40px;
  height: calc(90vh - 80px);
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  max-height: calc(60vh - 40px);
  min-height: 70%;
}
.bottom {
  hr {
    border-top: none;
    border-bottom: 1px solid #333;
  }
}

.sizeLeft {
  font-size: 0.8rem;
  font-weight: 400;
  color: #333fffb3;
}
.sizeRight {
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}
.price {
  font-weight: 300;
  font-size: 1.5rem;
  color: #333;
  margin: 20px 0;
}
.description {
  font-weight: 300;
  font-size: 0.75rem;
  color: #333;
  line-height: 25px;
  margin-bottom: 20px;
  max-height: 10vh;
}

.apiTitle {
  font-size: 1rem;
  font-weight: 400px;
  font-family: Roboto;
  color: #f2f2f28a;
}
.apiDescription {
  color: red;
  font-size: 0.8rem;
  pre {
    background: transparent !important; // important to overwrite the syntax-highlighter styles
  }
}
