@import "overwrites";

.title {
  font-size: 2.1rem;
  font-weight: 600;
  color: #383838;
}

.banner {
  margin-left: auto;
}

@media (min-width: 576px) {
  .titleSection {
    transform: translate(0px, -50%);
    position: absolute;
    left: 70px;
    top: 50%;
  }
  .banner {
    opacity: 0.2;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .banner {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .banner {
    opacity: 1;
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .banner {
    opacity: 1;
    width: 80%;
  }
}
