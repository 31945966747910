.alertBody {
  color: #aaa;
  font-weight: 300;
  > strong {
    font-weight: 600;
    color: #333;
  }
}
.heading {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 0;
  font-family: "Hind", "Roboto", sans-serif;
}
