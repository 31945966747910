/* #### Generated By: http://www.cufonfonts.com #### */
@import "overwrites.scss";

@font-face {
  font-family: "Ginora Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Ginora Sans Regular"), url("~assets/fonts/Ginóra Sans Regular.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Ginora Sans Oblique"), url("~assets/fonts/Ginóra Sans Oblique.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Ginora Sans Light"), url("~assets/fonts/Ginóra Sans Light.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Ginora Sans Light Oblique"),
    url("~assets/fonts/Ginóra Sans Light Oblique.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Ginora Sans Semi Bold"),
    url("~assets/fonts/Ginóra Sans Semi Bold.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Ginora Sans Semi Bold Oblique"),
    url("~assets/fonts/Ginóra Sans Semi Bold Oblique.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Ginora Sans Bold"), url("~assets/fonts/Ginóra Sans Bold.woff") format("woff");
}

@font-face {
  font-family: "Ginora Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Ginora Sans Bold Oblique"),
    url("~assets/fonts/Ginóra Sans Bold Oblique.woff") format("woff");
}
html {
  scroll-behavior: smooth;
}
html {
  font-size: 16px;
}
body {
  font-family: "Hind", "Roboto", sans-serif;
  background-color: #f2f2f2;
}

#footerXX {
  margin-left: -5px;
  // background: #ffffff;
  background-image: url("~assets/images/sf.png");
  background-repeat: no-repeat;
  // background-position: center;
  background-size: contain;
  height: 100px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

// we need this because hind font is asymmetric and it's hard to center it vertically with other elements
.hind {
  font-family: "Hind", "Roboto", sans-serif;
  padding-top: 4px;
  display: inline-flex;
}
.roboto {
  font-family: "Roboto", sans-serif !important;
}
.ginora {
  font-family: "Ginora Sans";
}

.link-green {
  transition: color 100ms;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  color: $green;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $darkGreen;
  }
  &::after {
    background: url("~assets/images/dashed_underline_lightgreen.png") repeat;
    position: absolute;
    content: "";
    bottom: 2px;
    height: 1px;
    right: 0;
    left: 0;
  }
  &:hover::after {
    background: url("~assets/images/dashed_underline_darkgreen.png") repeat;
  }
}
.link-black {
  color: #383838;
  font-size: 0.9rem;
  font-weight: 600;
  &:hover {
    color: #383838;
  }
}

[data-reach-menu-item][data-selected] {
  background: #f3f2fd;
  color: #333;
}
[data-reach-menu] {
  z-index: 3;
}
[data-reach-menu-list],
[data-reach-menu-items] {
  padding: 0;
}
[data-reach-dialog-content] {
  padding: 0;
  width: 70vw;
  max-width: 1000px;
}

.error-text {
  color: #e00d1f;
  font-weight: 300;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}
a[disabled] {
  cursor: not-allowed !important;
}
a[disabled]:active {
  pointer-events: none !important;
  opacity: 0.5;
}
