.tile {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  flex-direction: column;
  position: relative;
  display: flex;
  min-width: 240px;
  height: 471px;
  margin: 0 7.5px 20px;
  border-radius: 3px;
}
.imageBox {
  margin-bottom: 9px;
  max-height: 37%;
  height: 37%;
}
.imageBox button {
  border: 0;
}
.image {
  object-fit: cover;
  display: block;
  width: 100%;
  border-radius: 3px 3px 0 0;
}
.labels {
  > * + * {
    margin-left: 5px;
  }
  position: absolute;
  left: 15px;
  top: 15px;
}
.label {
  font-family: "Roboto";
  font-size: 0.75rem;
  border-radius: 20px;
  padding: 1px 10px 0;
  display: inline-flex;
  font-weight: 600;
  text-transform: uppercase;
  left: 15px;
  top: 15px;
  color: #fff;
}
.subtitle {
  font-family: "Hind";
  color: #383838;
  font-size: 1rem;
  opacity: 0.54;
}
.title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 1.625rem;
  height: 60px;
  line-height: 1.625rem;
  font-family: "Hind";
  color: #383838;
  overflow: hidden;
  font-weight: 400;
}
.productName {
  padding-right: 0;
  padding-left: 0;
}
.price {
  font-size: 1.375rem;
  font-family: "Hind";
  color: #383838;
}
:global(.btn).cartBtn {
  width: 100%;
}

.inputLabel {
  font-size: 0.875rem;
  font-family: "Hind";
  color: #383838;
  opacity: 0.54;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

@media (max-width: 767px) {
  .tileXs {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
  }
}
@media (min-width: 768px) {
  .tileXs {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}
@media (min-width: 992px) {
  .tileSm {
    flex: 0 0 25%;
    max-width: calc(25% - 15px);
  }
}
@media (min-width: 1280px) {
  .tileMd {
    flex: 0 0 20%;
    max-width: calc(20% - 15px);
  }
}
@media (min-width: 1600px) {
  .tileLg {
    flex: 0 0 16.666666%;
    max-width: calc(16.666666% - 15px);
  }
}
.typeLabel {
  font-size: 14px;
  color: rgba(56, 56, 56, 0.54);
}
.type {
  font-weight: 500;
  color: rgba(56, 56, 56, 0.75);
}
.typeWrapper {
  padding: 10px 0 15px;
}
.greyText {
  font-size: 1rem;
  color: #3838388a;
}
.contentStretcher {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
