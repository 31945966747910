.textGray {
  color: #3838388a;
}
.back {
  display: flex;
  margin-bottom: 5px;
}
.backLink {
  display: flex;
  font-size: 1rem;
  color: #00cf80;
  display: flex;
  align-items: center;
  font-weight: 400;
  img {
    width: 10px;
    height: 10px;
  }
  &:hover {
    color: #00cf80;
  }
}
.backLinkText {
  display: block;
  padding-top: 2px;
  margin-left: 10px;
}
