.filtersBtn {
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  color: #5047e2;
  display: none;
  position: relative;
  padding-left: 14px;
  background: transparent;
  border: 0;
  &:hover {
    color: rgb(114, 109, 230);
  }
  &:focus {
    color: #3f39b8;
    outline: none;
  }
}
.filtersBtn:before {
  content: "";
  position: absolute;
  left: 0;
  width: 10px;
  height: 2px;
  background: #5047e2;
  top: 8px;
}
.filtersBtn:after {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 10px;
  background: #5047e2;
  left: 4px;
  top: 4px;
}
.filtersBtn:hover .filtersBtn:after,
.filtersBtn:hover .filtersBtn:before {
  background: rgb(114, 109, 230);
}
.filtersBtn:focus .filtersBtn:after,
.filtersBtn:focus .filtersBtn:before {
  background: #3f39b8;
}
.heading {
  font-size: 1.6em;
  font-weight: 600;
  color: #000000e6;
  margin: 0 0 30px;
  display: none;
}
.wrapper {
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 991px) {
  .filtersBtn,
  .heading {
    display: block;
  }
  .wrapper {
    width: 100%;
  }
}
