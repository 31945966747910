.imgWrapper {
  max-height: none;
  max-width: none;
  width: 580px;
  margin: 0 0;
  position: relative;
}
.modRow {
  display: flex;
  position: absolute;
  top: 300px;
  margin: auto;
  align-content: center;
  align-items: center;
  // width: calc(100% + 900px);
  // left: -450px;
  width: 100%;
  left: 0;
}
