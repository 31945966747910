.popupWrapper {
  position: fixed;
  z-index: 1;
  height: 100vh;
  width: 100%;
  background: #5048e2;
}
.contentWrapper {
  max-width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.linkGreen {
  font-weight: 400;
  font-size: 1rem;
  color: #00cf80;
  &:hover,
  :active,
  :focus {
    color: #02975e;
    text-decoration: none;
  }
}
.headline {
  font-size: 26px;
  font-family: "Hind Light", "Roboto", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}
.text {
  font-family: "Hind Regular", "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.headline,
.text {
  text-align: center;
  color: #f2f2f2;
}
.illustration {
    max-width: 50vw;
}
@media (min-width: 468px) {
    .headline {
        font-size: 36px;
      }
}