@import "overwrites";

.hr {
  border-top: 2px solid $primary;
  margin-left: 0;
  width: 138px;
}

.grey {
  opacity: 0.54;
  font-size: 0.9rem;
  margin: 0;
}
.linkGreen {
  font-weight: 400;
  font-size: 1rem;
  color: #00cf80;
  &:hover,
  :active,
  :focus {
    color: #02975e;
    text-decoration: none;
  }
}