.container {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 700px;
  min-height: calc(100vh - 80px);
  overflow-anchor: none;
}
.text {
  font-size: 2.2rem;
  font-weight: 400;
  color: #555;
  margin: 0 auto;
  position: relative;
  width: 700px;
  z-index: 4;
  left: 40px;
}
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -50px;
}
.idea {
  margin: 0 5px;
}
.toCreate {
  opacity: 0;
  left: -350px;
  position: relative;
  display: none;
}
.devkit {
  color: #00cf80;
  font-weight: 600;
  left: 305px;
  position: absolute;
}
.motherboard {
  max-height: none;
  max-width: none;
  position: absolute;
}
.motherboardContainer {
  position: absolute;
  z-index: 1;
  display: none;
}
.motherboardShadow {
  height: 100%;
  width: 100%;
  max-height: none;
  max-width: none;
  position: absolute;
}
.mod {
  display: none;
  position: absolute;
  img {
    position: absolute;
    max-height: none;
    max-width: none;
  }
}

.componentsAside {
  position: absolute;
  right: 0;
  top: -25vh;
  font-size: 1.2rem;
  > .asideItem {
    opacity: 0;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
.subtitle {
  position: absolute;
  color: #555;
  font-weight: 600;
  padding: 0 20px;
  z-index: 4;
}
.laptop {
  position: absolute;
  top: -50%;
}

.cable {
  position: absolute;
  top: 3px;
  left: -213px;
}
.motherboardSolidBottom {
  position: absolute;
  top: 28px;
  display: none;
}
.motherboardSolidTop {
  position: absolute;
  display: none;
}
.pcb {
  position: absolute;
}
.base {
  left: 10px;
}
.base,
.motionSensor21Pin {
  z-index: 1;
}
.img {
  max-height: none;
  max-width: none;
  position: absolute;
}

.renderMask {
  // background-attachment: fixed;
  background-image: url("~assets/images/howItWorks/baby_monitor_finalne_pcb.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  // background-size: 630px;
  background-color: #f2f2f2;
  border-top: 3px solid #aaa;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.renderMask::after {
  content: "";
  position: absolute;
  background-color: #f2f2f2;
  left: 0;
  right: 0;
  height: 200px;
  bottom: -200px;
}
.hr {
  width: 100%;
  position: absolute;
  border-top: 3px solid;
  z-index: 2;
}
.initMask {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}
.modTitle {
  font-weight: 900;
}

@media (max-width: 600px) {
  .subtitle {
    margin-top: 40px;
  }
}
