.tag {
  // border: 2px transparent;
  border-radius: 15px;
  padding: 4px 9px 3px;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Roboto";
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-right: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: box-shadow 100ms;
  display: inline-flex;
  opacity: 0.58;
  &:hover {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1;
  }
  &:focus,
  :active {
    outline: none;
    box-shadow: none;
  }
  :active {
    color: #fff;
  }
}
.active {
  opacity: 1;
  background-color: red;
  color: #fff;
  // padding-right: 22px;
  // background: url(~assets/images/icon-8px_round-close.svg) no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 7px);
}
