.Line-item {
  /* margin-bottom: 20px; */
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
}
// TODO: change BEM names which are the legacy of bootstrapped app
.Line-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  min-width: 140px;
}

.Line-item__content {
  width: 100%;
  // padding-left: 75px;
}

.Line-item__variant-title {
  color: #767676;
  margin-right: 10px;
}

.Line-item__title {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3rem;
  font-family: "Hind";
  overflow: hidden;
  max-width: calc(100% - 40px);
}

.Line-item__price {
  font-size: 1.25rem;
  padding-right: 5px;
  text-align: right;
}

.Line-item__content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__remove {
  border: 0;
  background: transparent;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  height: 28px;
  width: 28px;
  min-width: 28px;
  position: relative;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px #5048e240 inset;
  }
}
.Line-item__remove img {
  height: 20px;
  margin-top: auto;
  margin-right: 0;
}
@media (max-width: 450px) {
  .Line-item__img {
    min-width: 70px;
  }
}
