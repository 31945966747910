.container {
  position: absolute;
  top: 0;
  left: 10vw;
  right: 10vw;
  background-color: #ffffff;
  z-index: 1;
  height: 100vh;
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000090;
  z-index: 1;
  backdrop-filter: blur(3px);
}
.content {
  position: relative;
  z-index: 1;
}
.input {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #a3a3a6;
  border-radius: 0;
  padding-right: 30px;
  padding-left: 0;
  margin-top: 15px;
  padding-right: 20px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: #5048e21c 0px 0px 0px 3px;
  }
}
.form {
  padding: 30px;
  position: relative;
}
.title {
  color: #a3a3a6;
  font-size: 1.2rem;
}
.recentSearches {
  padding: 30px;
  border-top: 1px solid #efeeec;
  max-height: calc(100vh - 105px);
  overflow: auto;
}
:global(.btn).searchPhrase {
  display: block;
  color: #878787;
  font-family: Roboto;
  font-size: 0.9rem;
  padding: 0 0.75rem 9px;
  font-weight: 400;
  &:hover {
    font-weight: 500;
  }
}
.phrases {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
:global(.btn).clearButton {
  position: absolute;
  top: 47px;
  right: 30px;
  padding: 0;
  background-color: #fff;
  border-color: #fff;
}
.arrow {
  transform: rotate(180deg);
  width: 21px;
  padding: 2px;
}
