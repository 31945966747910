.tag {
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 500;
  color: #333;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-right: 5px;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.detailsSection {
  width: 100%;
  margin-left: 40px;
  font-family: Roboto;
  h2 {
    color: #383838;
    font-weight: 300;
    font-size: 3rem;
    margin: 20px 0;
  }
}
.serialNumber {
  font-size: 1.125rem;
  color: #3838388a;
  font-weight: 400;
}
.price {
  font-size: 30px;
}
.selectWrapper {
  max-width: 200px;
}
.textGray {
  color: #3838388a;
}
.btnText {
  font-weight: 400;
  font-family: Hind;
  letter-spacing: 0.64px;
}
.secondaryBtn {
  border-color: #5048e233 !important;
}
