.back {
  display: flex;
  margin-bottom: 5px;
}
.backLink {
  border: 1px solid transparent;
  display: flex;
  font-size: 1rem;
  color: #00cf80;
  display: flex;
  align-items: center;
  font-weight: 400;
  background-color: transparent;
  img {
    width: 12px;
  }
  &:hover {
    color: #02975e;
    text-decoration: none;
  }
  &:focus,
  &:active {
    color: #02975e;
    outline: none;
  }
}
.backLinkText {
  display: block;
  padding-top: 2px;
  margin-left: 10px;
}
