$primary: #5048e2;
$green: #00cf80;
$darkGreen: #019688;

@import "~bootstrap/scss/bootstrap";

// .nav-link {
//   text-transform: uppercase;
//   font-weight: 500;
//   margin: 0 20px;
// }
// .navbar-light .navbar-nav .nav-link {
//   color: #fff;
// }
// .navbar-light .navbar-nav .nav-link {
//   &:hover,
//   &:active,
//   &:focus {
//     color: #fff;
//     opacity: 0.8;
//   }
//   &.active {
//     color: $green;
//   }
// }
h1,
h2 {
  font-family: Ginora Sans;
}

.btn {
  border-radius: 0;
  // padding needs to be overwritten because of Hind fonta asymmetry
  padding: 11px 0.75rem 9px;
  font-weight: 500;
  font-size: 1rem;
}
.btn-primary {
  border: 2px solid $primary;
}
.btn-secondary {
  background-color: transparent;
  border: 2px solid $primary;
  color: $primary;
}
.btn-success {
  background-color: #00cf80;
}

.btn-outline-primary {
  border-width: 2px;
}

.btn-link {
  color: #00cf80;
  &:hover {
    color: #02975e;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1860px;
  }
}

@media (min-width: 1680px) {
  .container {
    max-width: 1620px;
  }
}
