.h1 {
  font-size: 2rem;
  font-weight: 300;
}
.card {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  border: 1px solid #efeeec;
  background-color: #fff;
}
:global(.btn).linkGreen {
  font-weight: 400;
  color: #00cf80;
  display: inline-block;
  width: auto;
  align-self: baseline;
  padding: 5px 10px;
  &:hover {
    color: #02975e;
    text-decoration: none;
  }
}
.doneIcon {
  width: 60px;
}
.alertBody {
  color: #aaa;
  font-weight: 300;
  > strong {
    font-weight: 600;
    color: #333;
  }
}
