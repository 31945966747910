.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 33, 0.9);
}
.contentWrapper {
  position: relative;
  width: max-content;
  max-width: 1000px;
  min-width: 90%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  margin: 0px auto;
}
.contentInnerWrapper {
  position: relative;
  display: flex;
  max-height: 90vh;
  overflow: auto;
  padding: 40px;
}
.selectedPicture {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: #fff;
}
.selectedPicture img {
  display: block;
  width: 100%;
}
.gallery {
  width: 410px;
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.miniature {
  width: 60px;
  height: 60px;
  margin: 0 5px;
  border: none;
}
.closeModalBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: 0;
  padding: 2px;
  cursor: pointer;
}
.tabs {
  width: fit-content;
  box-shadow: 0px 0px 16px #0000004d;
}
.tab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 48px;
  border: 0;
  background-color: #35373e;
  box-shadow: inset 10px -10px 16px #0000004d;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #ffffffb3;
  opacity: 0.4;
}
.tabActive {
  opacity: 1;
  box-shadow: none;
}
.tabContent {
  padding: 40px;
  background-color: #35373e;
  box-shadow: 0px 0px 16px #0000004d;
  color: #fff;
}
.details {
    display: none;
}
.detailsActive {
    display: block;
}
.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 20px;
    padding: 3px 6px 0;
    line-height: 1;
    text-transform: uppercase;
    font-size: 0.75rem;
    border-radius: 10px;
    color: #f2f2f2;
    background: #999;
    margin-bottom: 20px;
}
.name strong,
.name {
  font-size: 2.45rem;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}
.name span {
    color: #ffffffb3;
}
.size,
.number {
    font-size: 0.9rem;
}
.size span,
.number {
  color: #ffffffb3;
  font-weight: 300;
}
.price,
.number {
  margin-bottom: 20px;
}
.size {
  margin-bottom: 15px;
}
.size strong {
  font-weight: 300;
}
.price {
  font-size: 2.25rem;
  font-weight: 200;
}
.description {
    font-size: 0.9rem;
  font-weight: 300;
  max-width: 800px;
}
.addBtn {
  text-transform: uppercase;
  font-size: 0.8rem !important;
  margin-bottom: 30px;
}
@media (min-width: 1600px) {
  .contentWrapper {
    width: max-content;
    max-width: 1280px;
  }
  .description {
    min-width: 800px;
  }
}
