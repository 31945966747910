.rotate180 {
  transform: rotate(180deg);
}
.formControl:global(.form-control) {
  padding-right: 5px !important;
  display: flex;
  border-radius: 0;
}
.arrow {
  width: 24px;
}
