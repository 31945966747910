.aside {
  width: 240px;
}
.heading {
  font-size: 1.6em;
  font-weight: 600;
  color: #000000e6;
  margin: 0 0 30px;
}
.filtersBtn {
  margin-bottom: 10px;
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #5047e2;
  position: relative;
  padding-left: 14px;
  background: transparent;
  border: 0;
  &:hover {
    color: rgb(114, 109, 230);
  }
  &:focus {
    color: #3f39b8;
    outline: none;
  }
}
.filtersBtn:before {
  content: "";
  position: absolute;
  left: 0;
  width: 10px;
  height: 2px;
  background: #5047e2;
  top: 8px;
}
.filtersBtn:hover .filtersBtn:before {
  background: rgb(114, 109, 230);
}
.filtersBtn:focus .filtersBtn:before {
  background: #3f39b8;
}
.filterTitle {
  font-family: Ginora Sans;
  font-size: 1.25rem;
  color: #3838388a;
}
.clearBtn:global(.btn) {
  border: 1px solid transparent;
  background: transparent;
  color: #00cf80;
  &[disabled] {
    border: 1px solid transparent;
    background: transparent;
    color: #00cf80;
    cursor: default;
  }
}
.clearBtn:global(.btn):not(:disabled):not(.disabled) {
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: #02975e;
    border: 1px solid transparent;
  }
}
@media (max-width: 991px) {
  .aside {
    width: 95%;
    position: fixed;
    right: -100%;
    z-index: 1;
    background: #fff;
    padding: 20px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    top: 100px;
    padding-top: 96px;
  }
  .asideOpen {
    right: 0;
  }
  .heading {
    display: none;
  }
}
@media (min-width: 992px) {
  .filtersBtn {
    display: none;
  }
}
