.container {
  display: flex;
  align-items: center;
  position: relative;
}
.btn {
  color: #5047e2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  font-family: monospace;
  padding: 0;
  border: 2px solid #ccc;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  padding-bottom: 2px;
  &:focus,
  &:active {
    box-shadow: 0 0 0 5px #5048e240;
    outline: none;
  }
}
.value {
  color: black;
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border: 2px solid #767676;
  padding: 0;
  border-radius: 0;
  margin: 0 -2px;
  z-index: 1;
}
.inputBox {
  position: absolute;
  z-index: 1;
  transition: width 200ms, left 200ms, opacity 200ms;
  overflow: hidden;
  > input {
    padding: 2px 10px;
    width: 100%;
    text-align: center;
  }
  &.hasError > input {
    border: 2px solid red;
  }
}
.hidden {
  visibility: hidden;
}
